import { getRequest, postRequest } from './api'

export async function list(size, index, searchTerm, orderBy) {
  return getRequest(`sepaRechnung/get-list/${size || 15}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`)
}

export async function find(id) {
  return getRequest(`sepaRechnung/edit/${id}`)
}

export async function save(values) {
  return postRequest(`sepaRechnung/save`, values)
}

export function remove(values) {
  return postRequest(`sepaRechnung/delete`, values)
}
