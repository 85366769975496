import { getRequest, postRequest, logRequest } from './api'

export async function list(size, index, searchTerm, orderBy) {
  logRequest("Show QuestionAnswer List")
  return getRequest(`questionAnswer/get-list/${size || 15}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`)
}

export async function filter(size, index, searchTerm, orderBy, question) {
  logRequest("Show QuestionAnswer List")
  return getRequest(`questionAnswer/get-list2/${size || 15}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}/${question || '%20'}`)
}

export async function find(id) {
  logRequest("Show QuestionAnswer", JSON.stringify(id))
  return getRequest(`questionAnswer/edit/${id}`)
}

export async function save(values) {
  logRequest("Save QuestionAnswer")
  return postRequest(`questionAnswer/save`, values)
}

export function remove(values) {
  logRequest("Delete QuestionAnswer", JSON.stringify(values.values))
  return postRequest(`questionAnswer/delete`, values)
}
 