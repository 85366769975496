const actions = {
  REQUESTING: 'Customers/REQUESTING',
  RESPONSE_ERROR: 'Customers/RESPONSE_ERROR',

  FETCH_CUSTOMERS: 'Customers/FETCH',
  LIST_CUSTOMER: 'Customer/LIST',

  FETCH_CUSTOMER: 'Customer/FETCH',
  NEW_CUSTOMER: 'Customer/NEW',
  EDIT_CUSTOMER: 'Customer/EDIT',
  RESET_CUSTOMER: 'Customer/RESET',

  RE_FETCH_CUSTOMERS: 'Customers/REFRESH',
  SAVE_CUSTOMER: 'Customer/SAVE',
  SAVE_CUSTOMER_RECIEVED: 'Customer/SAVE_CUSTOMER_RECIEVED',

  DELETE_CUSTOMER: 'Customer/DELETE',
  DELETE_CUSTOMER_RECIEVED: 'Customer/DELETE_RECIEVED',
}

export default actions
