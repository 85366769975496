import actions from './actions'

export default function ProvisionsReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null,
        saveSuccess: false,
      }
    case actions.EDIT_LANGUAGES:
      return {
        ...state,
        editing: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.NEW_LANGUAGES2:
      return {
        ...state,
        updateNew: 1,
        loading: false,
        saveSuccess: false,
      }
    case actions.RESET_LANGUAGES:
      return {
        ...state,
        updateNew: null,
        loading: false,
        saveSuccess: false,
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }
    default:
      return state
  }
}
