const actions = {
  REQUESTING: 'Vorlages/REQUESTING',
  RESPONSE_ERROR: 'Vorlages/RESPONSE_ERROR',

  FETCH_VORLAGES: 'Vorlages/FETCH',
  LIST_VORLAGE: 'Vorlage/LIST',

  FETCH_VORLAGE: 'Vorlage/FETCH',
  NEW_VORLAGE: 'Vorlage/NEW',
  EDIT_VORLAGE: 'Vorlage/EDIT',
  RESET_VORLAGE: 'Vorlage/RESET',

  RE_FETCH_VORLAGES: 'Vorlages/REFRESH',
  SAVE_VORLAGE: 'Vorlage/SAVE',
  SAVE_VORLAGE_RECIEVED: 'Vorlage/SAVE_VORLAGE_RECIEVED',

  DELETE_VORLAGE: 'Vorlage/DELETE',
  DELETE_VORLAGE_RECIEVED: 'Vorlage/DELETE_RECIEVED',
}

export default actions
