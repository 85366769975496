const actions = {
  REQUESTING: 'ContractSigneds/REQUESTING',
  RESPONSE_ERROR: 'ContractSigneds/RESPONSE_ERROR',

  FETCH_CONTRACTSIGNEDS: 'ContractSigneds/FETCH',
  LIST_CONTRACTSIGNED: 'ContractSigned/LIST',

  FETCH_CONTRACTSIGNED: 'ContractSigned/FETCH',
  NEW_CONTRACTSIGNED: 'ContractSigned/NEW',
  EDIT_CONTRACTSIGNED: 'ContractSigned/EDIT',
  RESET_CONTRACTSIGNED: 'ContractSigned/RESET',

  RE_FETCH_CONTRACTSIGNEDS: 'ContractSigneds/REFRESH',
  SAVE_CONTRACTSIGNED: 'ContractSigned/SAVE',
  SAVE_CONTRACTSIGNED_RECIEVED: 'ContractSigned/SAVE_CONTRACTSIGNED_RECIEVED',

  DELETE_CONTRACTSIGNED: 'ContractSigned/DELETE',
  DELETE_CONTRACTSIGNED_RECIEVED: 'ContractSigned/DELETE_RECIEVED',

  SAVE_START: 'ContractSigned/SAVE_START',
  SAVE_START_RECIEVED: 'ContractSigned/SAVE_START_RECIEVED',  
  
  SAVE_END: 'ContractSigned/SAVE_END',
  SAVE_END_RECIEVED: 'ContractSigned/SAVE_END_RECIEVED',  

}

export default actions
