const actions = {
  REQUESTING: 'Languages/REQUESTING',
  RESPONSE_ERROR: 'Languages/RESPONSE_ERROR',

  EDIT_LANGUAGES: 'LANGUAGES/EDIT',
  FETCH_LANGUAGES: 'Languages/FETCH',
  LIST_LANGUAGES: 'LANGUAGES/LIST',

  NEW_LANGUAGES: 'LANGUAGES/NEW',
  NEW_LANGUAGES2: 'LANGUAGES/NEW2',
  RESET_LANGUAGES: 'LANGUAGES/RESET',

  RE_FETCH_LANGUAGES: 'Languages/REFRESH',
  SAVE_LANGUAGES: 'LANGUAGES/SAVE',
  SAVE_LANGUAGES_RECIEVED: 'LANGUAGES/SAVE_LANGUAGES_RECIEVED',

  DELETE_LANGUAGES: 'LANGUAGES/DELETE',
  DELETE_LANGUAGES_RECIEVED: 'LANGUAGES/DELETE_RECIEVED',
}

export default actions
