import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { UserOutlined } from '@ant-design/icons'
import { Menu, Dropdown, Avatar, Badge } from 'antd'
import styles from './style.module.scss'

const mapStateToProps = ({ user }) => ({ user })

const ProfileMenu = ({ dispatch, user }) => {
  const [count, setCount] = useState(7)
  const { t } = useTranslation()
  const logout = e => {
    e.preventDefault()
    dispatch({
      type: 'user/LOGOUT',
    })
  }
  const addCount = () => {
    setCount(count + 1)
  }
  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <strong>
          {t('topBar.profileMenu.hello')}, {user.name || 'Anonymous'}
        </strong>
        <div>
          <strong className="mr-1">
            {t('topBar.profileMenu.billingPlan')}, {user.name || 'Anonymous'}
          </strong>
          Professional
        </div>
        <div>
          <strong>
            {t('topBar.profileMenu.role')}:{' '}
          </strong>
          {user.role || '—'}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <div>
          <strong>
            {t('topBar.profileMenu.email')}:{' '}
          </strong>
          {user.email || '—'}
          <br />
          <strong>
            {t('topBar.profileMenu.phone')}:{' '}
          </strong>
          {user.phone || '—'}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a href="#" onClick={e => e.preventDefault()}>
          <i className={`${styles.menuIcon} fe fe-user`} />
          {t('topBar.profileMenu.editProfile')}
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a href="#" onClick={logout}>
          <i className={`${styles.menuIcon} fe fe-log-out`} />
          {t('topBar.profileMenu.logout')}
        </a>
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']} onVisibleChange={addCount}>
      <div className={styles.dropdown}>
        <Badge count={count}>
          <Avatar className={styles.avatar} shape="square" size="large" icon={<UserOutlined />} />
        </Badge>
      </div>
    </Dropdown>
  )
}

export default connect(mapStateToProps)(ProfileMenu)
