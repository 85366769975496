const actions = {
  REQUESTING: 'Documents/REQUESTING',
  RESPONSE_ERROR: 'Documents/RESPONSE_ERROR',

  FETCH_DOCUMENTS: 'Documents/FETCH',
  LIST_DOCUMENT: 'Document/LIST',

  FETCH_DOCUMENT: 'Document/FETCH',
  NEW_DOCUMENT: 'Document/NEW',
  EDIT_DOCUMENT: 'Document/EDIT',
  RESET_DOCUMENT: 'Document/RESET',

  RE_FETCH_DOCUMENTS: 'Documents/REFRESH',
  SAVE_DOCUMENT: 'Document/SAVE',
  SAVE_DOCUMENT_RECIEVED: 'Document/SAVE_DOCUMENT_RECIEVED',

  DELETE_DOCUMENT: 'Document/DELETE',
  DELETE_DOCUMENT_RECIEVED: 'Document/DELETE_RECIEVED',
}

export default actions
