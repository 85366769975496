import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import {  find, save, remove, filter } from '../../services/questionAnswer'

const storageName = "questionAnswersLocalKey";

function* fetchFilterResults({
  size,
  index,
  searchTerm,
  orderBy,
  question, 
}) { 
  const ts = localStorage.getItem(storageName)
  if (ts !== undefined && ts != null) {
    try {
      const fe = JSON.parse(ts)
      if (fe != null) {
        if (size === undefined) size = fe.size || size
        if (index === undefined) index = fe.index || index
        if (searchTerm === undefined) searchTerm = fe.searchTerm || searchTerm
        if (orderBy === undefined) orderBy = fe.orderBy || orderBy
        question = fe.question || question
      }
    } catch (e) {
      console.log(e)
    }
  }
  size = size || localStorage.getItem('pageSize') || 15
  try {
    localStorage.setItem(storageName, JSON.stringify({
      size,
      index,
      searchTerm,
      orderBy,
      question, 
    }),
    )
  } catch (e) {
    console.log(e)
  }
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(
    filter,
    size,
    index,
    searchTerm,
    orderBy,
    question
  )
  yield put({
    type: actions.LIST_QUESTIONANSWER,
    result,
  })
}


function* fetchQuestionAnswerEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_QUESTIONANSWER,
    result,
  })
}

function* fetchQuestionAnswerSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SAVE_QUESTIONANSWER_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_QUESTIONANSWERS,
  })
}

function* fetchQuestionAnswerDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_QUESTIONANSWER_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_QUESTIONANSWERS,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_QUESTIONANSWER, fetchQuestionAnswerEdit),
    takeEvery(actions.SAVE_QUESTIONANSWER, fetchQuestionAnswerSave),
    takeEvery(actions.DELETE_QUESTIONANSWER, fetchQuestionAnswerDelete),
    takeEvery(actions.FETCH_QUESTIONANSWERS, fetchFilterResults),
    takeEvery(actions.RE_FETCH_QUESTIONANSWERS, fetchFilterResults),
  ])
}
