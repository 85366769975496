const actions = {
  REQUESTING: 'QuestionAnswers/REQUESTING',
  RESPONSE_ERROR: 'QuestionAnswers/RESPONSE_ERROR',

  FETCH_QUESTIONANSWERS: 'QuestionAnswers/FETCH',
  LIST_QUESTIONANSWER: 'QuestionAnswer/LIST',

  FETCH_QUESTIONANSWER: 'QuestionAnswer/FETCH',
  NEW_QUESTIONANSWER: 'QuestionAnswer/NEW',
  EDIT_QUESTIONANSWER: 'QuestionAnswer/EDIT',
  RESET_QUESTIONANSWER: 'QuestionAnswer/RESET',

  RE_FETCH_QUESTIONANSWERS: 'QuestionAnswers/REFRESH',
  SAVE_QUESTIONANSWER: 'QuestionAnswer/SAVE',
  SAVE_QUESTIONANSWER_RECIEVED: 'QuestionAnswer/SAVE_QUESTIONANSWER_RECIEVED',

  DELETE_QUESTIONANSWER: 'QuestionAnswer/DELETE',
  DELETE_QUESTIONANSWER_RECIEVED: 'QuestionAnswer/DELETE_RECIEVED',
}

export default actions
