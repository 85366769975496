const actions = {
  REQUESTING: 'CallCenterActivityLogs/REQUESTING',
  RESPONSE_ERROR: 'CallCenterActivityLogs/RESPONSE_ERROR',

  FETCH_CALLCENTERACTIVITYLOGS2: 'CallCenterActivityLogs/FETCH2',
  LIST_CALLCENTERACTIVITYLOG2: 'CallCenterActivityLogs/LIST2',
  
  FETCH_CALLCENTERACTIVITYLOGS: 'CallCenterActivityLogs/FETCH',
  LIST_CALLCENTERACTIVITYLOG: 'CallCenterActivityLog/LIST',

  FETCH_CALLCENTERACTIVITYLOG: 'CallCenterActivityLog/FETCH',
  NEW_CALLCENTERACTIVITYLOG: 'CallCenterActivityLog/NEW',
  EDIT_CALLCENTERACTIVITYLOG: 'CallCenterActivityLog/EDIT',
  RESET_CALLCENTERACTIVITYLOG: 'CallCenterActivityLog/RESET',

  RE_FETCH_CALLCENTERACTIVITYLOGS: 'CallCenterActivityLogs/REFRESH',
  SAVE_CALLCENTERACTIVITYLOG: 'CallCenterActivityLog/SAVE',
  SAVE_CALLCENTERACTIVITYLOG_RECIEVED: 'CallCenterActivityLog/SAVE_CALLCENTERACTIVITYLOG_RECIEVED',

  DELETE_CALLCENTERACTIVITYLOG: 'CallCenterActivityLog/DELETE',
  DELETE_CALLCENTERACTIVITYLOG_RECIEVED: 'CallCenterActivityLog/DELETE_RECIEVED',
  
  FETCH_CALLCENTERACTIVITYLOGS23: 'CallCenterActivityLogs/FETCH23',
  LIST_CALLCENTERACTIVITYLOG23: 'CallCenterActivityLog/LIST23',
  RESET_CALLCENTERACTIVITYLOG23: 'CallCenterActivityLog/RESET23',

  FETCH_CALLCENTERACTIVITYLOGS23_CLOSE: 'FETCH_CALLCENTERACTIVITYLOGS23_CLOSE',
  FETCH_CALLCENTERACTIVITYLOGS23_CLOSE_RECIEVED: 'FETCH_CALLCENTERACTIVITYLOGS23_CLOSE_RECIEVED',

  FETCH_CALLCENTERACTIVITYLOGS23_OPEN: 'FETCH_CALLCENTERACTIVITYLOGS23_OPEN',
  FETCH_CALLCENTERACTIVITYLOGS23_OPEN_RECIEVED: 'FETCH_CALLCENTERACTIVITYLOGS23_OPEN_RECIEVED',

}

export default actions
