const actions = {
  REQUESTING: 'ContractTexts/REQUESTING',
  RESPONSE_ERROR: 'ContractTexts/RESPONSE_ERROR',

  FETCH_CONTRACTTEXTS: 'ContractTexts/FETCH',
  LIST_CONTRACTTEXT: 'ContractText/LIST',

  FETCH_CONTRACTTEXT: 'ContractText/FETCH',
  NEW_CONTRACTTEXT: 'ContractText/NEW',
  EDIT_CONTRACTTEXT: 'ContractText/EDIT',
  RESET_CONTRACTTEXT: 'ContractText/RESET',

  RE_FETCH_CONTRACTTEXTS: 'ContractTexts/REFRESH',
  SAVE_CONTRACTTEXT: 'ContractText/SAVE',
  SAVE_CONTRACTTEXT_RECIEVED: 'ContractText/SAVE_CONTRACTTEXT_RECIEVED',

  DELETE_CONTRACTTEXT: 'ContractText/DELETE',
  DELETE_CONTRACTTEXT_RECIEVED: 'ContractText/DELETE_RECIEVED',
}

export default actions
