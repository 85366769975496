import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import ProfileEditModal from 'pages/profile'
import style from './style.module.scss'

export default () => {
  const { userss, settings } = useSelector(state => ({
    userss: state.userss,
    settings: state.settings
  }))

  return (
    <div>
      {userss.profile && <ProfileEditModal />}
      <div
        className={classNames(style.footer, {
          [style.footerFullWidth]: !settings.isContentMaxWidth,
        })}
      >
        <div className={style.inner}>
          <div className="row">
            <div className="col-md-8">
              {/* <p>
              <strong>Copyright &copy; 2021 Softlare GmbH </strong>
            </p> <p> 
              Air UI is a set of modern professional Html / React / Vue and Angular based templates.
              This is a powerful and super flexible tool, which suits best for any kind of web
              application: Web Sites, Web Applications, Hybrid Apps, CRM, CMS, Admin Panels, etc.
            </p> */}
              <a
                href="https://www.softlare.de"
                target="_blank"
                rel="noopener noreferrer"
                className={style.sellpixels}
              >
                CRM
                <span />
              </a>
              <br />
              <p className="mb-0">
                © {new Date().getFullYear()} Softlare GmbH | {' '}
                <a href="https://www.softlare.de/datenschutz" target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>
              </p>
            </div>
            {/* 
                <div className="col-md-4">
                  <div className={style.logo}>
                    <div className={style.logo__letter}>A</div>
                    <div className={style.logo__name}>{logo}</div>
                    <div className={style.logo__descr}>{description}</div>
                  </div>
                </div> 
            */}
          </div>
        </div>
      </div>
    </div>
  )
}
 