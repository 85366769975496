import actions from './actions'

export default function ContractSignedsReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null,
        saveSuccess: false,
      }
    case actions.SAVE_START_RECIEVED:
      return {
        ...state,
        loading: true,
      }
    case actions.SAVE_END_RECIEVED:
      return {
        ...state,
        loading: false,
      }
    case actions.LIST_CONTRACTSIGNED:
      return {
        ...state,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.EDIT_CONTRACTSIGNED:
      return {
        ...state,
        editing: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.RESET_CONTRACTSIGNED:
      return {
        ...state,
        editing: null,
        loading: false,
        saveSuccess: false,
      }
    case actions.NEW_CONTRACTSIGNED:
      return {
        ...state,
        editing: { contractSignedID: 0 },
        loading: false,
        saveSuccess: false,
      }
    case actions.CREATE_CONTRACTSIGNED_RECIEVED ||
      actions.UPDATE_CONTRACTSIGNED_RECIEVED ||
      actions.DELETE_CONTRACTSIGNED_RECIEVED:
      return {
        ...state,
        editing: { contractSignedID: 0 },
        loading: false,
        saveSuccess: true, // mark
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }
    default:
      return state
  }
}
