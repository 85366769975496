const actions = {
  REQUESTING: 'TimesheetLockeds/REQUESTING',
  RESPONSE_ERROR: 'TimesheetLockeds/RESPONSE_ERROR',

  FETCH_TIMESHEETLOCKEDS: 'TimesheetLockeds/FETCH',
  LIST_TIMESHEETLOCKED: 'TimesheetLocked/LIST',

  FETCH_TIMESHEETLOCKED: 'TimesheetLocked/FETCH',
  NEW_TIMESHEETLOCKED: 'TimesheetLocked/NEW',
  EDIT_TIMESHEETLOCKED: 'TimesheetLocked/EDIT',
  RESET_TIMESHEETLOCKED: 'TimesheetLocked/RESET',

  RE_FETCH_TIMESHEETLOCKEDS: 'TimesheetLockeds/REFRESH',
  SAVE_TIMESHEETLOCKED: 'TimesheetLocked/SAVE',
  SAVE_TIMESHEETLOCKED_RECIEVED: 'TimesheetLocked/SAVE_TIMESHEETLOCKED_RECIEVED',

  DELETE_TIMESHEETLOCKED: 'TimesheetLocked/DELETE',
  DELETE_TIMESHEETLOCKED_RECIEVED: 'TimesheetLocked/DELETE_RECIEVED',
}

export default actions
