import actions from './actions'

export default function CompanyManagersReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null,
        saveSuccess: false,
      }
    case actions.RESET_COMPANYMANAGERS2:
      return {
        ...state,
        loading: false,
        editing: null,
        result: null,
        alldata: null
      }
    case actions.GETALL_COMPANYMANAGERS_RECIEVED:
      return {
        ...state,
        alldata: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.LIST_COMPANYMANAGER:
      return {
        ...state,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.EDIT_COMPANYMANAGER:
      return {
        ...state,
        editing: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.RESET_COMPANYMANAGER:
      return {
        ...state,
        editing: null,
        loading: false,
        saveSuccess: false,
      }
    case actions.NEW_COMPANYMANAGER:
      return {
        ...state,
        editing: { companyManagerID: 0 },
        loading: false,
        saveSuccess: false,
        result: null,
      }
    case actions.SAVE_COMPANYMANAGER_RECIEVED2:
      console.log('get it')
      return {
        ...state,
        values: action.values,
      } 

    case actions.DELETE_COMPANYMANAGER_RECIEVED:
      return {
        ...state,
        editing: { companyManagerID: 0 },
        loading: false,
        saveSuccess: true, // mark
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }
    default:
      return state
  }
}
