import { all, takeEvery, put, call } from 'redux-saga/effects'

import { currentAccount, logout } from 'services/user'
import { history } from 'index'
import actions from './actions'

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const response = yield call(currentAccount)
  if (response) {
    const {
      userID,
      email,
      name,
      surname,
      photoURL: avatar,
      roleName,
      pageSize,
      userName,
      language,
      phone,
      mobile,
      passwordLocked,
    } = response
    let shortName
    try {
      shortName = `${name.substring(0, 1)}${surname.substring(0, 1)}`
      shortName = shortName.toUpperCase()
    } catch {
      //
    }

    yield put({
      type: actions.SET_STATE,
      payload: {
        id: userID,
        name: `${name} ${surname}`,
        email,
        avatar,
        role: roleName,
        authorized: true,
        pageSize,
        userName,
        language,
        passwordLocked,
        phone,
        mobile,
        shortName,
      },
    })

    localStorage.setItem('locale', language || 'de')
    localStorage.setItem('pageSize', pageSize || 15)
    yield put({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'locale',
        value: language || 'de',
      },
    })
    if (passwordLocked === true) {
      history.push('/system/lockscreen')
    }
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        authorized: false,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  yield call(logout)
  yield put({
    type: actions.SET_STATE,
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
      pageSize: 15,
      language: 'de',
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    LOAD_CURRENT_ACCOUNT(),
  ])
}
