const actions = {
  REQUESTING: 'SingleTokens/REQUESTING',
  RESPONSE_ERROR: 'SingleTokens/RESPONSE_ERROR',

  FETCH_SINGLETOKENS: 'SingleTokens/FETCH',
  LIST_SINGLETOKEN: 'SingleToken/LIST',

  FETCH_SINGLETOKEN: 'SingleToken/FETCH',
  NEW_SINGLETOKEN: 'SingleToken/NEW',
  EDIT_SINGLETOKEN: 'SingleToken/EDIT',
  RESET_SINGLETOKEN: 'SingleToken/RESET',

  RE_FETCH_SINGLETOKENS: 'SingleTokens/REFRESH',
  SAVE_SINGLETOKEN: 'SingleToken/SAVE',
  SAVE_SINGLETOKEN_RECIEVED: 'SingleToken/SAVE_SINGLETOKEN_RECIEVED',

  DELETE_SINGLETOKEN: 'SingleToken/DELETE',
  DELETE_SINGLETOKEN_RECIEVED: 'SingleToken/DELETE_RECIEVED',
}

export default actions
