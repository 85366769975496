import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import companyIGZs from './companyIGZs/sagas'
import callCenterActivityLogs from './callCenterActivityLogs/sagas'
import questionAnswers from './questionAnswers/sagas'
import dashboard from './dashboard/sagas'
import users from './userss/sagas'
import callRecords from './callRecords/sagas'
import mouseActivitys from './mouseActivitys/sagas'
import customers from './customers/sagas'
import contracts from './contracts/sagas'
import personals from './personals/sagas'
import offerArticles from './offerArticles/sagas'
import offers from './offers/sagas'
import travels from './travels/sagas'
import singleTokens from './singleTokens/sagas'
import provisions from './provisions/sagas'
import sepaRechnungs from './sepaRechnungs/sagas'
import timesheetLockeds from './timesheetLockeds/sagas'
import contractSigneds from './contractSigneds/sagas'
import documents from './documents/sagas'
import vorlages from './vorlages/sagas'
import userEvents from './userEvents/sagas'
import appSettingss from './appSettingss/sagas'
import cloudRechnungs from './cloudRechnungs/sagas'
import languages from './language/sagas'
import companyManagers from './companyManagers/sagas'
import contractTexts from './contractTexts/sagas'
import ftp from './ftp/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    settings(),
    companyIGZs(),
    callCenterActivityLogs(),
    questionAnswers(),
    dashboard(),
    users(),
    callRecords(),
    mouseActivitys(),
    customers(),
    contracts(),
    personals(),
    offers(),
    offerArticles(),
    languages(),
    travels(),
    singleTokens(),
    provisions(),
    sepaRechnungs(),
    timesheetLockeds(),
    contractSigneds(),
    documents(),
    vorlages(),
    userEvents(),
    appSettingss(),
    cloudRechnungs(),
    companyManagers(),
    contractTexts(),
    ftp(),

  ])
}
