import actions from './actions'

export default function reducer(state = { loading: false, path: '/', oldPath: undefined }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null,
      }
    case actions.commandRun_recieved:
      return {
        ...state,
        result: action.result,
        loading: false,
      }
    case actions.pathUpdate_recieved:
      return {
        ...state,
        path: action.payload.newPath,
        oldPath: action.payload.oldPath
      }
    case actions.pathReset_recieved:
      return {
        ...state,
        path: '/',
        oldPath: undefined
      }
    default:
      return state
  }
}
