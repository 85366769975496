import actions from './actions'

export default function QuestionAnswersReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null,
        saveSuccess: false,
      }
    case actions.LIST_QUESTIONANSWER:
      return {
        ...state,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.EDIT_QUESTIONANSWER:
      return {
        ...state,
        editing: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.RESET_QUESTIONANSWER:
      return {
        ...state,
        editing: null,
        loading: false,
        saveSuccess: false,
      }
    case actions.NEW_QUESTIONANSWER:
      return {
        ...state,
        editing: { questionAnswerID: 0 },
        loading: false,
        saveSuccess: false,
      }
    case actions.CREATE_QUESTIONANSWER_RECIEVED ||
      actions.UPDATE_QUESTIONANSWER_RECIEVED ||
      actions.DELETE_QUESTIONANSWER_RECIEVED:
      return {
        ...state,
        editing: { questionAnswerID: 0 },
        loading: false,
        saveSuccess: true, // mark
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }
    default:
      return state
  }
}
