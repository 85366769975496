import { getRequest, postRequest, logRequest } from './api'

export async function find(id) {
  logRequest('Show Company', undefined, id)
  return getRequest(`companyIGZ/edit/${id}`)
}

export async function find2(id) {
  logRequest('Show Company', undefined, id)
  return getRequest(`companyIGZ/edit2/${id}`)
}

export async function save(values2) {
  logRequest('Save Company')
  return postRequest(`companyIGZ/save`, { values: values2 })
}

export function remove(values) {
  logRequest('Delete Company', JSON.stringify(values.values))
  return postRequest(`companyIGZ/delete`, values)
}

export async function getFilterResults(
  size,
  index,
  searchTerm,
  orderBy,
  name1,
  name2,
  name3,
  city,
  methodName,
  postalCode,
  firmaOrigin,
  hauptsitz,
  telefon,
  username,
  email,
  reminderDate,
) {
  logRequest('Show Companies')
  const url = `companyIGZ/filter-results/${methodName}/${size || '15'}/${index ||
    '0'}/${searchTerm || '%20'}/${orderBy || '%20'}/${name1 || '%20'}/${name2 || '%20'}/${name3 ||
    '%20'}/${city || '%20'}/${postalCode || '%20'}/${firmaOrigin || '%20'}/${hauptsitz ||
    '%20'}/${telefon || '%20'}/${username || '%20'}/${email || '%20'}/${reminderDate || '%20'}`
  return getRequest(url)
}

export async function getRemindersForMe() {
  return getRequest(`companyIGZ/get-reminders-for-me-now`)
}

export async function getNotFilledManagerCompanys(size, index) {
  return getRequest(`companyIGZ/get-not-filled-managers/${size || '15'}/${index || '0'}`)
}

export async function getNotFilledManagerCompanysCount() {
  return getRequest(`companyIGZ/get-not-filled-managers-count`)
}
