import { getRequest, postRequest } from './api'

export async function list(size, index, searchTerm, orderBy) {
  return getRequest(`contractSigned/get-list/${size || 15}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`)
}

export async function find(id) {
  return getRequest(`contractSigned/edit/${id}`)
}

export async function save(values) {
  return postRequest(`contractSigned/save`, values)
}

export function remove(values) {
  return postRequest(`contractSigned/delete`, values)
}
