import { all, put, call } from 'redux-saga/effects'
import getMenuData from 'services/menu'
import getMenuData2 from 'services/menu/user'

export function* GET_DATA() {
  const menuData = yield call(getMenuData)
  const menuData2 = yield call(getMenuData2)
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuData,
      menuData2
    },
  })
}

export default function* rootSaga() {
  yield all([
    GET_DATA(), // run once on app load to fetch menu data
  ])
}
