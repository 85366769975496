const actions = {
  REQUESTING: 'Offers/REQUESTING',
  RESPONSE_ERROR: 'Offers/RESPONSE_ERROR',

  FETCH_OFFERS: 'Offers/FETCH',
  LIST_OFFER: 'Offer/LIST',

  FETCH_OFFER: 'Offer/FETCH',
  NEW_OFFER: 'Offer/NEW',
  EDIT_OFFER: 'Offer/EDIT',
  EDIT_OFFER2: 'Offer/ForCompany',
  EDIT_OFFER2_REC: 'Offer/ForCompanyRec',
  RESET_OFFER: 'Offer/RESET',

  RE_FETCH_OFFERS: 'Offers/REFRESH',
  SAVE_OFFER: 'Offer/SAVE',
  SAVE_OFFER_RECIEVED: 'Offer/SAVE_OFFER_RECIEVED',

  DELETE_OFFER: 'Offer/DELETE',
  DELETE_OFFER_RECIEVED: 'Offer/DELETE_RECIEVED',


}

export default actions
