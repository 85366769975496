import { getRequest } from './api'

export function currentAccount() {
  const token = localStorage.getItem('token')
  if (token === null) {
    return null
  }
  return getRequest(`token/me/${token}`)
}

export function logout() {
  const token = localStorage.getItem('token')
  localStorage.removeItem('token')
  localStorage.clear()
  return getRequest(`token/logout/${token}`)
}
