import { getRequest, postRequest, logRequest, getOfferID } from './api'

export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('offerID'))
    searchTerm = `${searchTerm}, offerID:${getOfferID()},`
 
    logRequest("Show Offer Articles", JSON.stringify(searchTerm))
    return getRequest(`offerArticle/get-list/${size || 15}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`)
}

export async function find(id) {
  logRequest("Show Offer Article", JSON.stringify(id))
  return getRequest(`offerArticle/edit/${id}`)
}

export async function save(values) {
  logRequest("Save Offer Article")
  return postRequest(`offerArticle/save`, values)
}

export function remove(values) {
  logRequest("Delete Offer Article", JSON.stringify(values.values))
  return postRequest(`offerArticle/delete`, values)
}

export async function createaARticles(id) {
  logRequest("Create Offer Articles", JSON.stringify(id))
  return getRequest(`offer/create/${id}`)
}

 