const actions = {
  REQUESTING: 'OfferArticles/REQUESTING',
  RESPONSE_ERROR: 'OfferArticles/RESPONSE_ERROR',

  FETCH_OFFERARTICLES: 'OfferArticles/FETCH',
  LIST_OFFERARTICLE: 'OfferArticle/LIST',

  FETCH_OFFERARTICLE: 'OfferArticle/FETCH',
  NEW_OFFERARTICLE: 'OfferArticle/NEW',
  EDIT_OFFERARTICLE: 'OfferArticle/EDIT',
  RESET_OFFERARTICLE: 'OfferArticle/RESET',

  RE_FETCH_OFFERARTICLES: 'OfferArticles/REFRESH',
  SAVE_OFFERARTICLE: 'OfferArticle/SAVE',
  SAVE_OFFERARTICLE_RECIEVED: 'OfferArticle/SAVE_OFFERARTICLE_RECIEVED',

  DELETE_OFFERARTICLE: 'OfferArticle/DELETE',
  DELETE_OFFERARTICLE_RECIEVED: 'OfferArticle/DELETE_RECIEVED',

  NEW_OFFERARTICLE2: 'OfferArticle/NEW2',
  NEW_OFFERARTICLE2_RECIEVED: 'OfferArticle/NEW2_RECIEVED',

}

export default actions
