import actions from './actions'

export default function CloudRechnungsReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null,
        saveSuccess: false,
      }
    case actions.LIST_CLOUDRECHNUNG:
      return {
        ...state,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.EDIT_CLOUDRECHNUNG:
      return {
        ...state,
        editing: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.RESET_CLOUDRECHNUNG:
      return {
        ...state,
        editing: null,
        loading: false,
        saveSuccess: false,
      }
    case actions.NEW_CLOUDRECHNUNG:
      return {
        ...state,
        editing: { cloudRechnungID: 0 },
        loading: false,
        saveSuccess: false,
      }
    case actions.CREATE_CLOUDRECHNUNG_RECIEVED ||
      actions.UPDATE_CLOUDRECHNUNG_RECIEVED ||
      actions.DELETE_CLOUDRECHNUNG_RECIEVED:
      return {
        ...state,
        editing: { cloudRechnungID: 0 },
        loading: false,
        saveSuccess: true, // mark
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }
    default:
      return state
  }
}
