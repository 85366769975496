import { getRequest, postRequest, getCompanyID } from './api'

export async function list(size, index, searchTerm, orderBy) {
  const companyID = getCompanyID()
  searchTerm = `CompanyID:${companyID},`
  return getRequest(`companyManager/get-list/${size || 15}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`)
}

export async function find(id) {
  return getRequest(`companyManager/edit/${id}`)
}

export async function save(values) {
  values.values.CompanyID = getCompanyID()
  return postRequest(`companyManager/save`, values)
}

export function remove(values) {
  return postRequest(`companyManager/delete`, values)
}

export async function getall() {
  return getRequest(`companyManager/get-json`)
}

export async function saveall(values) {
  return postRequest(`companyManager/save-all`, values)
}

export async function save2(values) { 
  return postRequest(`companyManager/save`, values)
}

// [HttpGet("get-json")]
// public IActionResult GetJson()
// {
//     return Ok(_companyManagerService.GetList());
// }

// [HttpPost("save-all")]
// public IActionResult SaveCompanyManager([FromBody] List<CompanyManager> model)
// {
//     if (!ModelState.IsValid)
//         return BadRequest(model);

//     var result = _companyManagerService.UpdateAll(model);
//     if (result)
//         return Ok();
//     return BadRequest();
// }