const actions = {
  REQUESTING: 'CloudRechnungs/REQUESTING',
  RESPONSE_ERROR: 'CloudRechnungs/RESPONSE_ERROR',

  FETCH_CLOUDRECHNUNGS: 'CloudRechnungs/FETCH',
  LIST_CLOUDRECHNUNG: 'CloudRechnung/LIST',

  FETCH_CLOUDRECHNUNG: 'CloudRechnung/FETCH',
  NEW_CLOUDRECHNUNG: 'CloudRechnung/NEW',
  EDIT_CLOUDRECHNUNG: 'CloudRechnung/EDIT',
  RESET_CLOUDRECHNUNG: 'CloudRechnung/RESET',

  RE_FETCH_CLOUDRECHNUNGS: 'CloudRechnungs/REFRESH',
  SAVE_CLOUDRECHNUNG: 'CloudRechnung/SAVE',
  SAVE_CLOUDRECHNUNG_RECIEVED: 'CloudRechnung/SAVE_CLOUDRECHNUNG_RECIEVED',

  DELETE_CLOUDRECHNUNG: 'CloudRechnung/DELETE',
  DELETE_CLOUDRECHNUNG_RECIEVED: 'CloudRechnung/DELETE_RECIEVED',
}

export default actions
