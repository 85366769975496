import { getRequest, postRequest, getUsername, getCompanyID } from './api'

export async function list(size, index, searchTerm, orderBy) {
  // logRequest("Show ActivityLog")
  return getRequest(`callCenterActivityLog/get-list/${size || 15}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`)
}

export async function find(id) {
  // logRequest("Show ActivityLog", JSON.stringify(id))
  return getRequest(`callCenterActivityLog/edit/${id}`)
}

export async function save(values) {
  // logRequest("Save ActivityLog")
  return postRequest(`callCenterActivityLog/save`, values)
}

export function remove(values) {
  // logRequest("Delete Customer", JSON.stringify(values.values))
  return postRequest(`callCenterActivityLog/delete`, values)
}

export async function getFilterResults(
  size,
  index,
  searchTerm,
  orderBy,
  user,
  date,
  message,
  companyID
) {
  // logRequest("Show ActivityLog")
  if (getCompanyID() !== '0') {
    companyID = getCompanyID()
  }

  const url = `callCenterActivityLog/filter-results/${size || '15'}/${index || '0'}/${searchTerm || '%20'}/${orderBy || '%20'}/${user || '%20'}/${date || '%20'}/${message || '%20'}/${companyID || '%20'}`
  return getRequest(url)
}

export async function getFilterResults2(
  size,
  index,
  searchTerm,
  orderBy,
  user,
  date,
  message,
  companyID
) {
  if (user === undefined || user === null || user === '') {
    user = getUsername()
  }
  const url = `callCenterActivityLog/filter-results/${size || '15'}/${index || '0'}/${searchTerm || '%20'}/${orderBy || '%20'}/${user || '%20'}/${date || '%20'}/${message || '%20'}/${companyID || '%20'}`
  return getRequest(url)
} 

export async function getFilterResults23(
  size,
  index,
  searchTerm,
  orderBy,
  user,
  date,
  message,
  companyID
) {
  // logRequest("Show ActivityLog")
  if (getCompanyID() !== '0') {
    companyID = getCompanyID()
  }

  const url = `callCenterActivityLog/filter-results23/${size || '15'}/${index || '0'}/${searchTerm || '%20'}/${orderBy || '%20'}/${user || '%20'}/${date || '%20'}/${message || '%20'}/${companyID || '%20'}`
  return getRequest(url)
}
