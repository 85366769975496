import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import grid from './grid/reducers'
import companyIGZs from './companyIGZs/reducers'
import callCenterActivityLogs from './callCenterActivityLogs/reducers'
import questionAnswers from './questionAnswers/reducers'
import dashboard from './dashboard/reducers'
import userss from './userss/reducers'
import callRecords from './callRecords/reducers'
import mouseActivitys from './mouseActivitys/reducers'
import customers from './customers/reducers'
import contracts from './contracts/reducers'
import personals from './personals/reducers'
import offers from './offers/reducers'
import offerArticles from './offerArticles/reducers'
import travels from './travels/reducers'
import singleTokens from './singleTokens/reducers'
import provisions from './provisions/reducers'
import sepaRechnungs from './sepaRechnungs/reducers'
import timesheetLockeds from './timesheetLockeds/reducers'
import contractSigneds from './contractSigneds/reducers'
import documents from './documents/reducers'
import vorlages from './vorlages/reducers'
import userEvents from './userEvents/reducers'
import appSettingss from './appSettingss/reducers'
import cloudRechnungs from './cloudRechnungs/reducers'
import languages from './language/reducers'
import companyManagers from './companyManagers/reducers'
import contractTexts from './contractTexts/reducers'
import ftp from './ftp/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    grid,
    companyIGZs,
    callCenterActivityLogs,
    questionAnswers,
    dashboard,
    userss,
    callRecords,
    mouseActivitys,
    customers,
    contracts,
    personals,
    offers,
    offerArticles,
    travels,
    singleTokens,
    provisions,
    sepaRechnungs,
    timesheetLockeds,
    contractSigneds,
    documents,
    vorlages,
    userEvents,
    appSettingss,
    cloudRechnungs,
    languages,
    companyManagers,
    contractTexts,
    ftp
    
  })