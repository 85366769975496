const actions = {
  REQUESTING: 'Contracts/REQUESTING',
  RESPONSE_ERROR: 'Contracts/RESPONSE_ERROR',

  FETCH_CONTRACTS: 'Contracts/FETCH',
  LIST_CONTRACT: 'Contract/LIST',

  FETCH_CONTRACT: 'Contract/FETCH',
  NEW_CONTRACT: 'Contract/NEW',
  EDIT_CONTRACT: 'Contract/EDIT',
  RESET_CONTRACT: 'Contract/RESET',

  RE_FETCH_CONTRACTS: 'Contracts/REFRESH',
  SAVE_CONTRACT: 'Contract/SAVE',
  SAVE_CONTRACT_RECIEVED: 'Contract/SAVE_CONTRACT_RECIEVED',

  DELETE_CONTRACT: 'Contract/DELETE',
  DELETE_CONTRACT_RECIEVED: 'Contract/DELETE_RECIEVED',

  
  CREATE_CONTRACT: 'Offer/CREATE_CONTRACT',
  CREATE_CONTRACT_RECIEVED: 'Offer/CREATE_CONTRACT_RECIEVED'
}

export default actions
