const actions = {
  REQUESTING: 'MouseActivitys/REQUESTING',
  RESPONSE_ERROR: 'MouseActivitys/RESPONSE_ERROR',

  FETCH_MOUSEACTIVITYS: 'MouseActivitys/FETCH',
  LIST_MOUSEACTIVITY: 'MouseActivity/LIST',

  FETCH_MOUSEACTIVITY: 'MouseActivity/FETCH',
  NEW_MOUSEACTIVITY: 'MouseActivity/NEW',
  EDIT_MOUSEACTIVITY: 'MouseActivity/EDIT',
  RESET_MOUSEACTIVITY: 'MouseActivity/RESET',

  RE_FETCH_MOUSEACTIVITYS: 'MouseActivitys/REFRESH',
  SAVE_MOUSEACTIVITY: 'MouseActivity/SAVE',
  SAVE_MOUSEACTIVITY_RECIEVED: 'MouseActivity/SAVE_MOUSEACTIVITY_RECIEVED',

  DELETE_MOUSEACTIVITY: 'MouseActivity/DELETE',
  DELETE_MOUSEACTIVITY_RECIEVED: 'MouseActivity/DELETE_RECIEVED',
}

export default actions
