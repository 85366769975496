export default async function getMenuData() {
  return [
    {
      category: true,
      key: 'menu',
    },
    {
      key: 'dashboard',
      url: '/dashboard/statistics',
      icon: 'fe fe-pie-chart',
    },
    {
      key: 'callcenter',
      icon: 'fe fe-phone',
      children: [
        {
          key: 'callNow',
          url: '/callcenter/callnow',
        },
        {
          key: 'interested',
          url: '/callcenter/interested',
        },
        {
          key: 'notInterested',
          url: '/callcenter/notinterested',
        },
        {
          key: 'angebote',
          url: '/callcenter/angebote',
        },
        {
          key: 'reminder',
          url: '/callcenter/reminder',
        },
        {
          key: 'demo',
          url: '/callcenter/demo'
        },
        {
          key: 'sold',
          url: '/callcenter/sold'
        },
        {
          key: 'deactive',
          url: '/callcenter/deactive'
        },

        // {
        //   key: 'undecided',
        //   url: '/callcenter/undecided',
        // },
        // {
        //   key: 'isAppointmentSet',
        //   url: '/callcenter/terminset',
        // },
        // {
        //   key: 'called',
        //   url: '/callcenter/called',
        // },
        // {
        //   key: 'emailSent',
        //   url: '/callcenter/emailsent',
        // },
        // {
        //   key: 'notanswer',
        //   url: '/callcenter/notanswer'
        // },
        {
          key: 'numberError',
          url: '/callcenter/numbererror'
        },
        {
          key: 'mail',
          url: '/callcenter/mail'
        },      
        {
          key: 'turk',
          url: '/callcenter/turk'
        }
        ,      
        {
          key: 'webError',
          url: '/callcenter/error'
        }

      ]
    },
    {
      key: 'questionAnswers',
      url: '/questionAnswers',
      icon: 'fe fe-compass',
    },
    {
      key: 'travels',
      icon: 'fe fe-map',
      url: '/travels',
    },
    {
      key: 'outlook',
      icon: 'fe fe-calendar',
      children: [
        {
          key: 'esrasoutlook',
          url: '/outlook-esra',
        },
        {
          key: 'uralsoutlook',
          url: '/outlook-ural',
        },
        {
          key: 'muratsoutlook',
          url: '/outlook-murat',
        },

      ],
    },
    {
      key: 'offers',
      url: '/offers',
      icon: 'fe fe-file-text',
    },
    {
      key: 'emailSettings',
      icon: 'fe fe-command',
      children: [
        {
          key: 'appSettings',
          url: '/appSettings/1',
        },
        {
          key: 'angebotEmailSettings',
          url: '/appSettings/10',
        },
      ],
    },
    {
      key: 'timesheet',
      url: '/timesheet/user',
      icon: 'fe fe-clock',
    },
    {
      key: 'contracts',
      url: '/contracts',
      icon: 'fe fe-shopping-cart'
    },
    {
      key: 'callCenterActivityLogs',
      url: '/logs2',
      icon: 'fe fe-layers',
    },
    {
      key: 'companyManagersEmpty',
      url: '/companyManagers/fill',
      icon: 'fe fe-user',
    }
  ]
}
