import actions from './actions'

export default function CustomersReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null,
        saveSuccess: false,
      }
    case actions.LIST_CUSTOMER:
      return {
        ...state,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.EDIT_CUSTOMER:
      return {
        ...state,
        editing: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.RESET_CUSTOMER:
      return {
        ...state,
        editing: null,
        loading: false,
        saveSuccess: false,
      }
    case actions.NEW_CUSTOMER:
      return {
        ...state,
        editing: { CustomerID: 0 },
        loading: false,
        saveSuccess: false,
      }
    case actions.CREATE_CUSTOMER_RECIEVED ||
      actions.UPDATE_CUSTOMER_RECIEVED ||
      actions.DELETE_CUSTOMER_RECIEVED:
      return {
        ...state,
        editing: { CustomerID: 0 },
        loading: false,
        saveSuccess: true, // mark
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }
    default:
      return state
  }
}
