const actions = {
  REQUESTING: 'Personals/REQUESTING',
  RESPONSE_ERROR: 'Personals/RESPONSE_ERROR',

  FETCH_PERSONALS: 'Personals/FETCH',
  LIST_PERSONAL: 'Personal/LIST',

  FETCH_PERSONAL: 'Personal/FETCH',
  NEW_PERSONAL: 'Personal/NEW',
  EDIT_PERSONAL: 'Personal/EDIT',
  RESET_PERSONAL: 'Personal/RESET',

  RE_FETCH_PERSONALS: 'Personals/REFRESH',
  SAVE_PERSONAL: 'Personal/SAVE',
  SAVE_PERSONAL_RECIEVED: 'Personal/SAVE_PERSONAL_RECIEVED',

  DELETE_PERSONAL: 'Personal/DELETE',
  DELETE_PERSONAL_RECIEVED: 'Personal/DELETE_RECIEVED',
}

export default actions
