import { getRequest, postRequest, logRequest, postRequestReport } from './api'

export async function getDashboard() {
  return getRequest('dashboard/get-dashboard')
}
export async function getOutgoingCalls() {
  return getRequest('dashboard/get-outgoing-calls')
}
export async function getTotalCounts() {
  return getRequest('dashboard/get-total-counts')
}


export async function getWhatThinks() {
  logRequest("Show Dashboard")
  return getRequest('dashboard/get-what-thinks')
}

export async function getTimes(user, date, date2, message) {
  return getRequest(`dashboard/get-times/${user || '%20'}/${date || "%20"}/${date2 || '%20'}/${message || '%20'}`)
}

export async function getCallTimes(user, date, date2, message) {
  logRequest("Show ActivityCalendar")
  return getRequest(`dashboard/get-call-times/${user || '%20'}/${date || "%20"}/${date2 || '%20'}/${message || '%20'}`)
}

export async function getTimeSheet(user, year, month) {
  logRequest("Show Timesheet")
  return getRequest(`dashboard/get-timesheet/${user || '%20'}/${year || "%20"}/${month || '%20'}`)
}
 

export async function getStundenzettelviewfortimes(user, year) { 
  return getRequest(`dashboard/get-stundenzettelviewfortimes/${user || '%20'}/${year || "%20"}`)
}
 
export async function updateTimesheet2(user, year, column, value) {
  logRequest("Update Timesheet")
  return postRequest(`dashboard/update-timesheet`, {
    values: {
      user,
      column,
      value,
      year
    }
  })
}

export async function updateDurationsYo(user) {
  return getRequest(`dashboard/get-durations/${user || '%20'}`)
}

export async function getRecalls() {
  return getRequest(`companyigz/get-callbacks`)
}

export async function getReminders() {
  return getRequest(`companyigz/get-reminders`)
}

export async function getActivityTimes2() {
  return getRequest('calendar/get-activity-times')
}

export async function getCallTimes2() {
  return getRequest('calendar/get-call-times2')
}

export async function getCallAndLogTimes() {
  return getRequest('calendar/get-callandactivity-times')
}

export async function getCalculateAgain(userId, month, year) {
  logRequest("Calculate Timesheet")
  return getRequest(`calendar/timesheet-calculate-all/${userId}/${month}/${year}`)
}

export async function timesheetSendViaEmailCall(userId, month, year, emails) {
  const token = localStorage.getItem('token')
  return postRequestReport(`report/timesheetsendemail`, {
    values: {
      userId,
      month,
      year,
      emails,
      token
    }
  })
} 

export async function timesheetLock(userId, month, year) {
  logRequest("Lock Timesheet")
  return getRequest(`calendar/timesheet-lock/${userId}/${month}/${year}`) 
} 
 
export async function createTimesheet(userId, year, month) {
  logRequest("Create Timesheet")
  return getRequest(`dashboard/create-timesheet/${userId}/${year}/${month}`) 
} 