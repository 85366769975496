import actions from './actions'

export default function UserEventsReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null,
        start: null,
        end: null,
        userId: null,
      }
    case actions.LIST_USEREVENT:
      return {
        ...state,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.EDIT_USEREVENT:
      return {
        ...state,
        loading: false,
        editing: action.result,
        
        start: action.start,
        end: action.end,
        userId: action.userId,
      }
    case actions.RESET_USEREVENT:
      return {
        ...state,
        editing: null,
        loading: false,
        saveSuccess: false,
      }
    case actions.NEW_USEREVENT:
      return {
        ...state,
        editing: { userEventID: 0 },
        loading: false,
        saveSuccess: false,
      }
    case actions.CREATE_USEREVENT_RECIEVED ||
      actions.UPDATE_USEREVENT_RECIEVED ||
      actions.DELETE_USEREVENT_RECIEVED:
      return {
        ...state,
        editing: { userEventID: 0 },
        loading: false,
        saveSuccess: true, // mark
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }
    default:
      return state
  }
}
