import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { commandRun } from '../../services/ftp'

function* CommandRun2(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(commandRun, values)
  yield put({
    type: actions.commandRun_recieved,
    result,
  })
}

function* pathUpdate2({ newPath, oldPath }) {
  yield put({
    type: actions.pathUpdate_recieved,
    payload: { newPath, oldPath },
  })
  notification.success({ message: 'Success' })
}

function* pathReset2() {
  yield put({
    type: actions.pathReset_recieved,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.commandRun, CommandRun2),
    takeEvery(actions.pathUpdate, pathUpdate2),
    takeEvery(actions.pathReset, pathReset2),
  ])
}
