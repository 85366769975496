import 'antd/lib/style/index.less' // antd core styles
import './@kit/vendors/antd/themes/default.less' // default theme antd components
import './@kit/vendors/antd/themes/dark.less' // dark theme antd components
import './global.scss' // app & third-party component styles

import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
// import createActivityDetector from 'activity-detector'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
// import { logger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import { composeWithDevTools } from 'redux-devtools-extension'

import moment from 'moment'

// import { getRequest } from 'services/api'

import reducers from './redux/reducers'
import sagas from './redux/sagas'
import Router from './router'
import * as serviceWorker from './serviceWorker'

import './i18n';

// mocking api
// import 'services/axios/fakeApi'

// middlewared
const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]
// if (process.env.NODE_ENV === 'development') {
//   middlewares.push(logger)
// }
const store = createStore(
  reducers(history),
  composeWithDevTools(compose(applyMiddleware(...middlewares))),
)
sagaMiddleware.run(sagas)

// function useIdle(options) {
//   const [isIdle, setIsIdle] = React.useState(false)
//   React.useEffect(() => {
//     const activityDetector = createActivityDetector({ timeToIdle: 1000 })
//     activityDetector.on('idle', () => { setIsIdle(true); console.log('Hello there!') })
//     activityDetector.on('active', () => { setIsIdle(false); console.log('Are you still there?') })
//     return () => activityDetector.stop()
//   }, [])
//   return isIdle
// }
// const parseJwt = token => {
//   try {
//     return JSON.parse(atob(token.split('.')[1]))
//   } catch (e) {
//     return null
//   }
// }

// let didEnteredOnce = false
// let sessionLocked = false
// const minutesTimeout = 60000 * 20

// const activityDetector = createActivityDetector({ timeToIdle: minutesTimeout })
// activityDetector.on('idle', () => {
//   if (didEnteredOnce && sessionLocked === false && window.location.pathname !== '/auth/lockscreen') {
//     const token2 = localStorage.getItem('token')
//     if (token2) {
//       console.log('Session end.')
//       sessionLocked = true
//       window.location.href = '/auth/lockscreen'
//     }
//   }
// })

// activityDetector.on('active', () => {
//   if (window.location.pathname !== '/auth/lockscreen') {
//     if (sessionLocked === false) {
//       const token2 = localStorage.getItem('token')
//       if (token2) {
//         console.log('Hello there!')
//         didEnteredOnce = true
//       }
//     }
//   }
// })

// window.addEventListener('beforeunload', (event) => {
//   event.preventDefault();
//   const token = localStorage.getItem("token");
//   localStorage.clear();
//   getRequest(`token/logout/${token}`)

//   // event.returnValue = ''
// })
// window.addEventListener('unload', () => {
//   const token = localStorage.getItem("token");
//   localStorage.clear();
//   getRequest(`token/logout/${token}`)
// })

ReactDOM.render(
  <Suspense fallback={null}>
    <Provider store={store}>
      <Router history={history} />
    </Provider>
  </Suspense>
  ,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
export { store, history }

// 60000 = 1 minute
const timeoutLength = 60000 * 20
let timeoutTimerID

function startTimer() {
  // window.setTimeout returns an Id that can be used to start and stop a timer
  timeoutTimerID = window.setTimeout(timeout, timeoutLength)
}

function timeout() {
  if (window.location.pathname !== '/auth/lockscreen') {
    window.clearTimeout(timeoutTimerID)
    console.log('timeout')
    window.location.href = '/auth/lockscreen'
  }
}

function resetTimer() {
  window.clearTimeout(timeoutTimerID)
  startTimer()
}

function setupTimers() {
  document.addEventListener('mousemove', resetTimer, false)
  document.addEventListener('mousedown', resetTimer, false)
  document.addEventListener('keypress', resetTimer, false)
  document.addEventListener('touchmove', resetTimer, false)
  document.addEventListener('onscroll', resetTimer, false)
  startTimer()
}

function addNotification(item) {
  /*eslint-disable*/
  try {
    const notifications = localStorage.getItem("notifications");
    let exists = false
    let clearNotifications = false
    let oNotifications = []
    if (notifications !== null && notifications !== undefined) {
      oNotifications = JSON.parse(notifications);
      if (Array.isArray(oNotifications)) {
        for (let i = 0; i < oNotifications.length; i += 1) {
          if (oNotifications[i].companyIGZID === item.companyIGZID) {
            exists = true
          }
        }
      }
    } else {
      clearNotifications = true
    }
    if (clearNotifications === true || exists === false) {
      oNotifications.push(item);
      const p = JSON.stringify(oNotifications);
      localStorage.setItem('notifications', p);
      console.log(oNotifications);
      createNotification(item);
    }
  } catch (e) {
    console.log(e)
  }
}

function createNotification(item) {
  console.log(item);
  if (document.getElementById(`id_${item.companyIGZID}`) !== null && document.getElementById(`id_${item.companyIGZID}`) !== undefined) {
    console.log('Already added')
  } else {
    // eslint-disable-next-line prefer-template
    const myvar = '<div class="card notification_custom" id="id_' + item.companyIGZID + '">' +
      '    <div class="card-body">' +
      '      <a class="close_notification" onclick="document.getElementById(\'id_' + item.companyIGZID + '\').remove();">' +
      '        <i class="fe fe-x"></i>' +
      '      </a>' +
      '      <div class="title23">' +
      '        Erinnerungstitel' +
      '      </div>' +
      '      <div class="firma_name"><a>' + item.name1 + '</a></div>' + // href="/company/' + item.companyIGZID + '"
      '      <div class="date_name">' + moment(item.reminderDate, 'YYYY-MM-DD').format('DD/MM/YYYY') + ' ' + item.reminderTime + '</div>' +
      '    </div>' +
      '  </div>';
    let child = document.createElement('div');
    child.innerHTML = myvar;
    child = child.firstChild;
    document.getElementById('notifications').appendChild(child);
  }
}

// function checkNotifications(){
//   const notifications = localStorage.getItem("notifications");
//   const oNotifications = []
//   if (notifications !== null && notifications !== undefined) {
//     oNotifications = JSON.parse(notifications);
//     if (Array.isArray(oNotifications)) {
//       for (let i = 0; i < oNotifications.length; i += 1) {
//         if (oNotifications[i].companyIGZID === item.companyIGZID) {
//         }
//       }
//     }
//   }
// }

// let reminderIntervalId;
function checkReminders() {
  const reminders = localStorage.getItem('reminder')
  try {
    const oReminders = JSON.parse(reminders);

    const notifications = localStorage.getItem("notifications");

    let oNotifications = []
    try {
      if (notifications !== null && notifications !== undefined) {
        oNotifications = JSON.parse(notifications);
      }
    } catch (e) { console.log(e) };

    if (Array.isArray(oReminders)) {
      console.log('Good check dates now.');
      for (let i = 0; i < oReminders.length; i += 1) {
        // console.log(oReminders[i].name1, oReminders[i].reminderDate);
        // console.log(moment())
        const date = oReminders[i].reminderDate.split('T')[0] // 2022-05-25T00:00:00
        const date2 = moment(date, 'YYYY-MM-DD');
        const time = oReminders[i].reminderTime.split(':')
        date2.add(parseInt(time[0], 0), 'hours');
        date2.add(parseInt(time[1], 0), 'minutes');
        console.log(date2)
        const duration = moment.duration(date2.diff(moment()));
        const m = duration.asMinutes()
        console.log(m)
        if (m > 0 && m < 2) {
          // alert('New call reminder');
          addNotification(oReminders[i]);
        } else {
          const oNotificationsUpdate = []
          for (let j = 0; j < oNotifications.length; j += 1) {
            if (oNotifications[j].companyIGZID !== oReminders[i].companyIGZID) {
              oNotificationsUpdate.push(oNotifications[i])
            }
          }
          localStorage.setItem('notifications', JSON.stringify(oNotificationsUpdate))
        }
      }
    } else {
      console.log('Thats notarray')
    }
  } catch (e) {
    console.log(e);
  }
}

function createInterval() {
  window.setInterval(function () {
    checkReminders()
  }, 30000);
}

window.onload = () => {
  const element = document.createElement('div');
  element.setAttribute("id", "notifications");
  document.body.appendChild(element);
  setupTimers()
  createInterval()
  checkReminders()
  const token2 = localStorage.getItem('token')
  if (token2) {
    console.log('Session started.')
    localStorage.setItem('session', Date.now())
  }
}
