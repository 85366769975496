const actions = {
  REQUESTING: 'CallRecords/REQUESTING',
  RESPONSE_ERROR: 'CallRecords/RESPONSE_ERROR',

  FETCH_CALLRECORDS: 'CallRecords/FETCH',
  LIST_CALLRECORD: 'CallRecord/LIST',

  FETCH_CALLRECORD: 'CallRecord/FETCH',
  NEW_CALLRECORD: 'CallRecord/NEW',
  EDIT_CALLRECORD: 'CallRecord/EDIT',
  RESET_CALLRECORD: 'CallRecord/RESET',

  RE_FETCH_CALLRECORDS: 'CallRecords/REFRESH',
  SAVE_CALLRECORD: 'CallRecord/SAVE',
  SAVE_CALLRECORD_RECIEVED: 'CallRecord/SAVE_CALLRECORD_RECIEVED',

  DELETE_CALLRECORD: 'CallRecord/DELETE',
  DELETE_CALLRECORD_RECIEVED: 'CallRecord/DELETE_RECIEVED',

  CSV_IMPORT_MODAL: 'CSV_IMPORT_MODAL',
  CSV_IMPORT_CALL: 'CSV_IMPORT_CALL',
  CSV_IMPORT_CALL_RECIEVED: 'CSV_IMPORT_CALL_RECIEVED',
  
  FETCH_CALLRECORDS2: 'CallRecords/FETCH2',
  LIST_CALLRECORD2: 'CallRecord/LIST2',

  CALCULATE_TIMESHEET: 'CALCULATE_TIMESHEET',
  CALCULATE_TIMESHEET_RECIEVED: 'CALCULATE_TIMESHEET_RECIEVED',

  FETCH_GET_THIS_MONTH_OUTGOING_CALL: 'CallRecords/FETCH_GET_THIS_MONTH_OUTGOING_CALL',
  FETCH_GET_THIS_MONTH_OUTGOING_CALL_RECIEVED: 'CallRecord/FETCH_GET_THIS_MONTH_OUTGOING_CALL_RECIEVED'

}

export default actions
