import axios from 'axios'
import NProgress from 'nprogress'
import { notification } from 'antd'
import { store } from 'index'
import { API_URL, API_URL_REPORT, NETWORK_ERROR, NETWORK_ERROR_MESSAGE } from '../constants/index'

const token = localStorage.getItem('token')

const config = {
  headers: { Authorization: `Bearer ${token}` },
}

const netWorkErrorNotification = () => {
  notification.error({
    message: NETWORK_ERROR,
    duration: 0,
    description: NETWORK_ERROR_MESSAGE,
  })
}

export const getOfferID = () => {
  const state = store.getState()
  return state && state.offers && state.offers.editing && state.offers.editing.offerID
    ? state.offers.editing.offerID
    : '0'
}

export const getCompanyID = () => {
  const state = store.getState()
  return state &&
    state.companyIGZs &&
    state.companyIGZs.editing &&
    state.companyIGZs.editing.companyIGZID
    ? state.companyIGZs.editing.companyIGZID
    : '0'
}

export const getUsername = () => {
  const state = store.getState()
  return state && state.user && state.user.userName ? state.user.userName : ''
}

export const getDocumentFetchParameters = () => {
  const state = store.getState()
  const { contractSigneds } = state
  const { editing } = contractSigneds
  if (editing && editing.contractSignedID !== 0) {
    return `SecondaryTableID:${editing.contractSignedID},SecondaryTableName:ContractSigned,`
  }
  return `SecondaryTableID:0,SecondaryTableName:ContractSigned,`
}

const errorNotificationUnknown2 = message => {
  notification.error({
    message: 'Error',
    description: message,
    duration: 0,
  })
}

const errorNotificationUnknown = message => {
  notification.error({
    message: NETWORK_ERROR,
    description: message,
    duration: 0,
  })
}

export const getMicroBaseApiUrl = () => {
  return API_URL
}

export async function downloadRequest(url) {
  window.open(`${getMicroBaseApiUrl(url)}${url}`)
}

export async function postRequestReport(url, param) {
  // console.log(url, param)
  try {
    const response = await axios.post(`${API_URL_REPORT}/${url}`, param.values, {
      headers: { 'Content-Type': `application/x-www-form-urlencoded` },
    })
    // Success 🎉
    return response.data
  } catch (error) {
    // Error 😨
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      if (typeof error.response.data === 'string' || error.response.data instanceof String) {
        if (error.response.status === 400) {
          errorNotificationUnknown2(error.response.data)
        } else {
          errorNotificationUnknown(error.response.data)
        }
      } else {
        errorNotificationUnknown(error.message)
      }
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
      if (error.response.status === 401) {
        localStorage.removeItem('token')
      }
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      console.log(error.request)
    } else {
      // Something happened in setting up the request and triggered an Error
      console.log('Error', error.message)
    }
    if (error.message === 'Network Error') {
      if (error.response.status !== 401) {
        netWorkErrorNotification()
      }
    }
    return null
  }
}

export async function upload(url, data) {
  const config2 = config
  // config2.headers['Content-Type'] = 'multipart/form-data'
  try {
    const response = await axios.post(url, data, config2)
    return response.data
  } catch (error) {
    // Error 😨
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      if (typeof error.response.data === 'string' || error.response.data instanceof String) {
        if (error.response.status === 400) {
          errorNotificationUnknown2(error.response.data)
        } else {
          errorNotificationUnknown(error.response.data)
        }
      } else {
        errorNotificationUnknown(error.message)
      }
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
      if (error.response.status === 401) {
        localStorage.removeItem('token')
      }
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      console.log(error.request)
    } else {
      // Something happened in setting up the request and triggered an Error
      console.log('Error', error.message)
    }
    if (error.message === 'Network Error') {
      if (error.response.status !== 401) {
        netWorkErrorNotification()
      }
    }
    return null
  }
}
export async function getRequest(url) {
  // console.log(url)
  try {
    const response = await axios.get(`${getMicroBaseApiUrl(url)}${url}`, config)
    // Success 🎉
    return response.data
  } catch (error) {
    // Error 😨
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
      if (error.response.status === 401) {
        localStorage.removeItem('token')
      }
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      console.log(error.request)
      errorNotificationUnknown(error.message)
    } else {
      // Something happened in setting up the request and triggered an Error
      console.log('Error', error.message)
    }

    if (error.message === 'Network Error') {
      netWorkErrorNotification()
    }

    if (error.response.status === 400) {
      if (typeof error.response.data === 'string' || error.response.data instanceof String) {
        errorNotificationUnknown2(error.response.data)
      } else {
        errorNotificationUnknown2(error.message)
      }
    } else if (error.response.status !== 401) {
      errorNotificationUnknown(error.message)
    }

    return null
  }
}

export async function logRequest(message, companyName, companyID) {
  await axios.post(
    `${API_URL}acte/log`,
    {
      m: btoa(message),
      c: btoa(companyName || ''),
      i: btoa(companyID || ''),
    },
    config,
  )
}

export async function postRequest(url, param) {
  // console.log(url, param)
  try {
    const response = await axios.post(`${getMicroBaseApiUrl(url)}${url}`, param.values, config)
    // Success 🎉
    return response.data
  } catch (error) {
    // Error 😨
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      if (typeof error.response.data === 'string' || error.response.data instanceof String) {
        if (error.response.status === 400) {
          errorNotificationUnknown2(error.response.data)
        } else {
          errorNotificationUnknown(error.response.data)
        }
      } else {
        errorNotificationUnknown(error.message)
      }
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
      if (error.response.status === 401) {
        localStorage.removeItem('token')
      }
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      console.log(error.request)
    } else {
      // Something happened in setting up the request and triggered an Error
      console.log('Error', error.message)
    }
    if (error.message === 'Network Error') {
      if (error.response.status !== 401) {
        netWorkErrorNotification()
      }
    }
    return null
  }
}

axios.interceptors.request.use(
  c => {
    // Do something before request is sent
    // console.log('Start Ajax Call');
    if (NProgress.isStarted() === false) {
      NProgress.start()
    }
    return c
  },
  error => {
    // Do something with request error
    NProgress.done()
    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  response => {
    // Do something with response data
    // console.log('Done with Ajax call');
    if (NProgress.isStarted() === true) {
      NProgress.done()
    }
    return response
  },
  error => {
    // Do something with response error
    NProgress.done()
    return Promise.reject(error)
  },
)
