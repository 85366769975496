import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC } from '../../constants'
import { find, save } from '../../services/language'

function* edit2() {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, 1)
  yield put({
    type: actions.EDIT_LANGUAGES,
    result,
  })
}

function* save2(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SAVE_LANGUAGES_RECIEVED,
    result,
  })
  const result2 = yield call(find, 1)
  yield put({
    type: actions.EDIT_LANGUAGES,
    result: result2,
  })
}

function* new2() {
  yield put({
    type: actions.NEW_LANGUAGES2,
  }) 
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.NEW_LANGUAGES, new2),
    takeEvery(actions.FETCH_LANGUAGES, edit2),
    takeEvery(actions.SAVE_LANGUAGES, save2),
  ])
}
