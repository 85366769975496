import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { list, find, save, remove, csvpost, list2, outgoingCalls } from '../../services/callRecord'

function* fetchCallRecordEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_CALLRECORD,
    result,
  })
}

function* fetchCallRecordSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SAVE_CALLRECORD_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_CALLRECORDS,
  })
}

function* fetchCallRecordDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_CALLRECORD_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_CALLRECORDS,
  })
}

function* fetchCSVPost(values){
  yield put({
    type: actions.REQUESTING,
  })

  const result = yield call(csvpost, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }

  yield put({
    type: actions.CSV_IMPORT_CALL_RECIEVED,
    result,
  });

  yield put({
    type: actions.RE_FETCH_CALLRECORDS,
  });

  // try {
  //   yield put({
  //     type: actions.CALCULATE_TIMESHEET,
  //   });
  //   yield call(calculateTimesheet);
  //   yield put({
  //     type: actions.CALCULATE_TIMESHEET_RECIEVED,
  //   });
  // }catch (e){
  //   console.log(e);
  // }

  
}

function* fetchFilterResults({
  size,
  index,
  searchTerm,
  orderBy, 
  user,
  date,
  date2
}) { 
  const t = localStorage.getItem("callRecords")
  if (t !== undefined && t != null) {
    try {
      const fe = JSON.parse(t)
      if (fe != null) {
        if (size === undefined) size = fe.size || size
        if (index === undefined) index = fe.index || index
        if (searchTerm === undefined) searchTerm = fe.searchTerm || searchTerm
        if (orderBy === undefined) orderBy = fe.orderBy || orderBy
        user = fe.user || user
        date = fe.date || date
        date2 = fe.date2 || date2
      }
    } catch (e) {
      console.log(e)
    }
  }
  size = size || localStorage.getItem('pageSize') || 15
  try {
    localStorage.setItem("callRecords", JSON.stringify({
      size,
      index,
      searchTerm,
      orderBy,
      user,
      date,
      date2
    }),
    )
  } catch (e) {
    console.log(e)
  }
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(
    list,
    size,
    index,
    searchTerm,
    orderBy, 
    user,
    date,
    date2
  )
  yield put({
    type: actions.LIST_CALLRECORD,
    result,
  })
}

function* fetchFilterResults2({
  size,
  index,
  searchTerm,
  orderBy, 
  user,
  date,
  date2
}) { 
  const t = localStorage.getItem("callRecords2")
  if (t !== undefined && t != null) {
    try {
      const fe = JSON.parse(t)
      if (fe != null) {
        if (size === undefined) size = fe.size || size
        if (index === undefined) index = fe.index || index
        if (searchTerm === undefined) searchTerm = fe.searchTerm || searchTerm
        if (orderBy === undefined) orderBy = fe.orderBy || orderBy
        user = fe.user || user
        date = fe.date || date
        date2 = fe.date2 || date2
      }
    } catch (e) {
      console.log(e)
    }
  }
  size = size || localStorage.getItem('pageSize') || 15
  try {
    localStorage.setItem("callRecords2", JSON.stringify({
      size,
      index,
      searchTerm,
      orderBy,
      user,
      date,
      date2
    }),
    )
  } catch (e) {
    console.log(e)
  }

  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(
    list2,
    size,
    index,
    searchTerm,
    orderBy, 
    user,
    date,
    date2
  )
  yield put({
    type: actions.LIST_CALLRECORD2,
    result,
  })

}


function* getOutgoingCalls() {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(outgoingCalls)
  yield put({
    type: actions.FETCH_GET_THIS_MONTH_OUTGOING_CALL_RECIEVED,
    result,
  })
}
 

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_CALLRECORDS, fetchFilterResults),
    takeEvery(actions.FETCH_CALLRECORDS2, fetchFilterResults2),
    takeEvery(actions.FETCH_CALLRECORD, fetchCallRecordEdit),
    takeEvery(actions.SAVE_CALLRECORD, fetchCallRecordSave),
    takeEvery(actions.CSV_IMPORT_CALL, fetchCSVPost),
    takeEvery(actions.DELETE_CALLRECORD, fetchCallRecordDelete),
    takeEvery(actions.RE_FETCH_CALLRECORDS, fetchFilterResults),
    takeEvery(actions.FETCH_GET_THIS_MONTH_OUTGOING_CALL, getOutgoingCalls),

  ])
}
