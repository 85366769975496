import { getRequest, postRequest, logRequest } from './api'

export async function list(size, index, searchTerm, orderBy, user, date, date2) {
  if (!searchTerm)
    searchTerm = 'Richtung:gehend'
    logRequest("Show CallRecords", searchTerm)
    return getRequest(`callRecord/get-list/${size || 15}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}/${user || '%20'}/${date || '%20'}/${date2 || '%20'}`)
}

export async function list2(size, index, searchTerm, orderBy, user, date, date2) {
  if (!searchTerm)
    searchTerm = 'Richtung:kommend'
    logRequest("Show CallRecords", searchTerm)
    return getRequest(`callRecord/get-list/${size || 15}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}/${user || '%20'}/${date || '%20'}/${date2 || '%20'}`)
}

export async function find(id) {
  logRequest("Show Call Record", JSON.stringify(id))
  return getRequest(`callRecord/edit/${id}`)
}

export async function save(values) {
  logRequest("Save Call Record")
  return postRequest(`callRecord/save`, values)
}

export function remove(values) {
  logRequest("Delete Call Record", JSON.stringify(values.values))
  return postRequest(`callRecord/delete`, values)
}

export async function csvpost(values) {
  logRequest("Import Call Records")
  return postRequest(`callRecord/CsvImport`, values)
}

export async function calculateTimesheet() {
  logRequest("Timesheet Callculate All")
  return getRequest(`calendar/timesheet-calculate-all`)
}
 
export async function outgoingCalls() {
  return getRequest(`callRecord/get-this-month-outgoing-calls`)
}
