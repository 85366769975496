const actions = {
  REQUESTING: 'Provisions/REQUESTING',
  RESPONSE_ERROR: 'Provisions/RESPONSE_ERROR',

  FETCH_PROVISIONS: 'Provisions/FETCH',
  LIST_PROVISION: 'Provision/LIST',

  FETCH_PROVISION: 'Provision/FETCH',
  NEW_PROVISION: 'Provision/NEW',
  EDIT_PROVISION: 'Provision/EDIT',
  RESET_PROVISION: 'Provision/RESET',

  RE_FETCH_PROVISIONS: 'Provisions/REFRESH',
  SAVE_PROVISION: 'Provision/SAVE',
  SAVE_PROVISION_RECIEVED: 'Provision/SAVE_PROVISION_RECIEVED',

  DELETE_PROVISION: 'Provision/DELETE',
  DELETE_PROVISION_RECIEVED: 'Provision/DELETE_RECIEVED',
}

export default actions
