const actions = {
  REQUESTING: 'AppSettingss/REQUESTING',
  RESPONSE_ERROR: 'AppSettingss/RESPONSE_ERROR',

  FETCH_APPSETTINGSS: 'AppSettingss/FETCH',
  LIST_APPSETTINGS: 'AppSettings/LIST',

  FETCH_APPSETTINGS: 'AppSettings/FETCH',
  NEW_APPSETTINGS: 'AppSettings/NEW',
  EDIT_APPSETTINGS: 'AppSettings/EDIT',
  RESET_APPSETTINGS: 'AppSettings/RESET',

  RE_FETCH_APPSETTINGSS: 'AppSettingss/REFRESH',
  SAVE_APPSETTINGS: 'AppSettings/SAVE',
  SAVE_APPSETTINGS_RECIEVED: 'AppSettings/SAVE_APPSETTINGS_RECIEVED',

  DELETE_APPSETTINGS: 'AppSettings/DELETE',
  DELETE_APPSETTINGS_RECIEVED: 'AppSettings/DELETE_RECIEVED',
}

export default actions
