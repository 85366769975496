const actions = {
  REQUESTING: 'CompanyIGZs/REQUESTING',
  RESPONSE_ERROR: 'CompanyIGZs/RESPONSE_ERROR',

  FETCH_COMPANYIGZIDS: 'CompanyIGZs/FETCH',
  LIST_COMPANYIGZID: 'CompanyIGZ/LIST',

  FETCH_COMPANYIGZID: 'CompanyIGZ/FETCH',
  FETCH_COMPANYIGZID2: 'CompanyIGZ/FETCH2',
  NEW_COMPANYIGZID: 'CompanyIGZ/NEW',
  EDIT_COMPANYIGZID: 'CompanyIGZ/EDIT',
  RESET_COMPANYIGZID: 'CompanyIGZ/RESET',

  RE_FETCH_COMPANYIGZIDS: 'CompanyIGZs/REFRESH',
  SAVE_COMPANYIGZID: 'CompanyIGZ/SAVE',
  SAVE_COMPANYIGZID_RECIEVED: 'CompanyIGZ/SAVE_COMPANYIGZID_RECIEVED',

  DELETE_COMPANYIGZID: 'CompanyIGZ/DELETE',
  DELETE_COMPANYIGZID_RECIEVED: 'CompanyIGZ/DELETE_RECIEVED',

  FETCH_COMPANYIGZIDS_callnow: 'CompanyIGZs/FETCH_callnow',
  FETCH_COMPANYIGZIDS_interested: 'CompanyIGZs/FETCH_interested',
  FETCH_COMPANYIGZIDS_undecided: 'CompanyIGZs/FETCH_undecided',
  FETCH_COMPANYIGZIDS_notinterested: 'CompanyIGZs/FETCH_notinterested',
  FETCH_COMPANYIGZIDS_termin: 'CompanyIGZs/FETCH_termin',
  FETCH_COMPANYIGZIDS_called: 'CompanyIGZs/FETCH_called',
  FETCH_COMPANYIGZIDS_email: 'CompanyIGZs/FETCH_email',
  FETCH_notanswer: 'CompanyIGZs/FETCH_notanswer',
  FETCH_COMPANYIGZIDS_numbererror: 'CompanyIGZs/FETCH_numbererror',

  FETCH_COMPANYIGZIDS_FILTER: 'CompanyIGZ/Filter',
  
  OUTLOOK_MODAL_CLOSE: 'OUTLOOK_MODAL_CLOSE',
  OUTLOOK_MODAL_CLOSE_RECIEVED: 'OUTLOOK_MODAL_CLOSE_RECIEVED',

  OUTLOOK_MODAL_OPEN: 'OUTLOOK_MODAL_OPEN',
  OUTLOOK_MODAL_OPEN_RECIEVED: 'OUTLOOK_MODAL_OPEN_RECIEVED',

  EMAIL_SEND_FROM_LIST: 'EMAIL_SEND_FROM_LIST',
  EMAIL_SEND_FROM_LIST_RECIEVED: 'EMAIL_SEND_FROM_LIST_RECIEVED',

  FETCH_REMAINDER_FOR_ME: 'FETCH_REMAINDER_FOR_ME',
  FETCH_REMAINDER_FOR_ME_RECIEVED: 'FETCH_REMAINDER_FOR_ME_RECIEVED',  

  getNotFilledManagerCompanys: 'getNotFilledManagerCompanys',
  getNotFilledManagerCompanys_recieved: 'getNotFilledManagerCompanys_recieved',

  managerUpdated: 'managerUpdated',
  managerUpdated_recieved: 'managerUpdated_recieved',

  getNotFilledManagerCompanysCount: 'getNotFilledManagerCompanysCount',
  getNotFilledManagerCompanysCount_recieved: 'getNotFilledManagerCompanysCount_recieved',
}

export default actions
