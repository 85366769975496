import actions from './actions'

export default function CallRecordsReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        outgoingCalls: undefined,
        loading: true,
        editing: null,
        saveSuccess: false,
        csvUploaded: false
      }
    case actions.CALCULATE_TIMESHEET:
      return {
        ...state,
        calculating: true,
        loading: false,
      }
    case actions.CALCULATE_TIMESHEET_RECIEVED:
      return {
        ...state,
        calculating: false,
        loading: false,
      }
    case actions.LIST_CALLRECORD:
      return {
        ...state,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.LIST_CALLRECORD2:
      return {
        ...state,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.EDIT_CALLRECORD:
      return {
        ...state,
        editing: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.CSV_IMPORT_CALL_RECIEVED:
      return {
        ...state,
        csv: null,
        loading: false,
        saveSuccess: false,
        csvUploaded: true
      }
    case actions.CSV_IMPORT_MODAL:
      return {
        ...state,
        csv: true,
        loading: false,
        saveSuccess: false,
      }

    case actions.RESET_CALLRECORD:
      return {
        ...state,
        editing: null,
        loading: false,
        saveSuccess: false,
      }
    case actions.NEW_CALLRECORD:
      return {
        ...state,
        editing: { callRecordID: 0 },
        loading: false,
        saveSuccess: false,
      }
    case actions.CREATE_CALLRECORD_RECIEVED ||
      actions.UPDATE_CALLRECORD_RECIEVED ||
      actions.DELETE_CALLRECORD_RECIEVED:
      return {
        ...state,
        editing: { callRecordID: 0 },
        loading: false,
        saveSuccess: true, // mark
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }

    case actions.FETCH_GET_THIS_MONTH_OUTGOING_CALL_RECIEVED:
      return {
        ...state,
        outgoingCalls: action.result,
        loading: false, 
      }
    default:
      return state
  }
}
