const actions = {
  REQUESTING: 'Travels/REQUESTING',
  RESPONSE_ERROR: 'Travels/RESPONSE_ERROR',

  FETCH_TRAVELS: 'Travels/FETCH',
  LIST_TRAVEL: 'Travel/LIST',

  FETCH_TRAVEL: 'Travel/FETCH',
  NEW_TRAVEL: 'Travel/NEW',
  EDIT_TRAVEL: 'Travel/EDIT',
  RESET_TRAVEL: 'Travel/RESET',

  RE_FETCH_TRAVELS: 'Travels/REFRESH',
  SAVE_TRAVEL: 'Travel/SAVE',
  SAVE_TRAVEL_RECIEVED: 'Travel/SAVE_TRAVEL_RECIEVED',
  SAVE_TRAVEL2: 'Travel/SAVE2',

  DELETE_TRAVEL: 'Travel/DELETE',
  DELETE_TRAVEL_RECIEVED: 'Travel/DELETE_RECIEVED',
}

export default actions
