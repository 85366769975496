import React from 'react'
import { Menu, Dropdown } from 'antd'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import styles from './style.module.scss'

const mapStateToProps = ({ settings }) => ({
  locale: settings.locale,
})

const LanguageSwitcher = ({ dispatch, locale }) => {
  const { i18n } = useTranslation();
  const changeLanguage = ({ key }) => {
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'locale',
        value: key,
      },
    });
    i18n.changeLanguage(key);
  }
  const language = locale.toString().substr(0, 2)
  const menu = (
    <Menu selectedKeys={[locale]} onClick={changeLanguage}>
      <Menu.Item key="en">
        <span className="text-uppercase font-size-12 mr-2">EN</span>
        English
      </Menu.Item>
      <Menu.Item key="de">
        <span className="text-uppercase font-size-12 mr-2">DE</span>
        Deutsch
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
      <div className={styles.dropdown}>
        <span className="text-uppercase">
          <img src={`/resources/images/${language === "de" ? "de" : "en"}.svg`} className="la" alt="Language" />
        </span>
      </div>
    </Dropdown>
  )
}

export default connect(mapStateToProps)(LanguageSwitcher)
