const actions = {
  REQUESTING: 'CompanyManagers/REQUESTING',
  RESPONSE_ERROR: 'CompanyManagers/RESPONSE_ERROR',
  
  RESET_COMPANYMANAGERS: 'RESET_COMPANYMANAGERS',
  RESET_COMPANYMANAGERS2: 'RESET_COMPANYMANAGERS2',

  FETCH_COMPANYMANAGERS: 'CompanyManagers/FETCH',
  LIST_COMPANYMANAGER: 'CompanyManager/LIST',

  FETCH_COMPANYMANAGER: 'CompanyManager/FETCH',
  NEW_COMPANYMANAGER: 'CompanyManager/NEW',
  EDIT_COMPANYMANAGER: 'CompanyManager/EDIT',
  RESET_COMPANYMANAGER: 'CompanyManager/RESET',

  RE_FETCH_COMPANYMANAGERS: 'CompanyManagers/REFRESH',

  SAVE_COMPANYMANAGER: 'CompanyManager/SAVE',
  SAVE_COMPANYMANAGER_RECIEVED: 'CompanyManager/SAVE_COMPANYMANAGER_RECIEVED',

  DELETE_COMPANYMANAGER: 'CompanyManager/DELETE',
  DELETE_COMPANYMANAGER_RECIEVED: 'CompanyManager/DELETE_RECIEVED',
  
  GETALL_COMPANYMANAGERS: 'CompanyManagers/GETALL_COMPANYMANAGERS',

  GETALL_COMPANYMANAGERS_RECIEVED: 'CompanyManagers/GETALL_COMPANYMANAGERS_RECIEVED',
  SAVE_COMPANYMANAGER_ALL: 'CompanyManager/SAVE_COMPANYMANAGER_ALL',

  SAVE_COMPANYMANAGER2: 'CompanyManager/SAVE2',
  SAVE_COMPANYMANAGER_RECIEVED2: 'CompanyManager/SAVE_COMPANYMANAGER_RECIEVED2',

  
}

export default actions
