const actions = {
  REQUESTING: 'FTP_FILE/REQUESTING',
  RESPONSE_ERROR: 'FTP_FILE/RESPONSE_ERROR',

  commandRun: 'commandRun',
  commandRun_recieved: 'commandRun_recieved', 
  
  pathUpdate: 'pathUpdate',
  pathUpdate_recieved: 'pathUpdate_recieved', 
  
  pathReset: 'pathReset',
  pathReset_recieved: 'pathReset_recieved',


}

export default actions
