const actions = {
  REQUESTING: 'UserEvents/REQUESTING',
  RESPONSE_ERROR: 'UserEvents/RESPONSE_ERROR',

  FETCH_USEREVENTS: 'UserEvents/FETCH',
  LIST_USEREVENT: 'UserEvent/LIST',

  FETCH_USEREVENT: 'UserEvent/FETCH',
  NEW_USEREVENT: 'UserEvent/NEW',
  EDIT_USEREVENT: 'UserEvent/EDIT',
  RESET_USEREVENT: 'UserEvent/RESET',

  RE_FETCH_USEREVENTS: 'UserEvents/REFRESH',
  SAVE_USEREVENT: 'UserEvent/SAVE',
  SAVE_USEREVENT_RECIEVED: 'UserEvent/SAVE_USEREVENT_RECIEVED',

  DELETE_USEREVENT: 'UserEvent/DELETE',
  DELETE_USEREVENT_RECIEVED: 'UserEvent/DELETE_RECIEVED',
}

export default actions
