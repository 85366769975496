import actions from './actions'

export default function CompanyIGZsReducer(state = { loading: false, outlook: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        saveSuccess: false,
      }
    case actions.LIST_COMPANYIGZID:
      return {
        ...state,
        // editing: null,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.EDIT_COMPANYIGZID:
      return {
        ...state,
        editing: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.FETCH_REMAINDER_FOR_ME_RECIEVED:
      return {
        ...state,
        reminderList: null,
        loading: false,
      }
    case actions.getNotFilledManagerCompanysCount_recieved:
      return {
        ...state,
        countNotFilled: action.result,
        loading: false
      }
    case actions.getNotFilledManagerCompanys_recieved:
      return {
        ...state,
        result: action.result,
        loading: false,
      }
    case actions.managerUpdated_recieved:
      return {
        ...state,
        result: action.payload,
        loading: false,
      }
    case actions.RESET_COMPANYIGZID:
      return {
        ...state,
        editing: null,
        loading: false,
        saveSuccess: false,
      }
    case actions.NEW_COMPANYIGZID:
      return {
        ...state,
        editing: { CompanyIGZID: 0 },
        loading: false,
        saveSuccess: false,
      }
    case actions.DELETE_COMPANYIGZID_RECIEVED:
      return {
        ...state,
        editing: { CompanyIGZID: 0 },
        loading: false,
        saveSuccess: true, // mark
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }
    case actions.OUTLOOK_MODAL_OPEN_RECIEVED:
      return {
        ...state,
        outlook: true,
      }
    case actions.OUTLOOK_MODAL_CLOSE_RECIEVED:
      return {
        ...state,
        outlook: false,
      }

    default:
      return state
  }
}
