import React, { } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { Menu, Dropdown, Avatar, Badge } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import actionsUser from 'redux/userss/actions'
import styles from './style.module.scss'

export default () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const { user } = useSelector(state => ({
    user: state.user,
    userss: state.userss,
  }));

  const logout = e => {
    e.preventDefault()
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  const openProfile = () => {
    dispatch({
      type: actionsUser.FETCH_PROFILE,
      id: user.id,
    })
  }

  const menu = (
    <Menu selectable={false}>
      <Menu.Item key="1" style={{ cursor: 'default' }}>
        <strong>
          {t('hello')}, {user.userName}
        </strong>
        <div>{user.email}</div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="12">
        <a href="#" onClick={openProfile}>
          <i className={`${styles.menuIcon} fe fe-user`} />
          {t('editProfile')}
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="13">
        <a href="#" onClick={logout}>
          <i className={`${styles.menuIcon} fe fe-log-out`} />
          {t('logout')}
        </a>
      </Menu.Item>
    </Menu>
  )
  return (
    <div>
      <Dropdown overlay={menu} trigger={['click']}>
        <div className={styles.dropdown}>
          <Badge count={0}>
            <Avatar className={styles.avatar} shape="square" size="large" icon={<UserOutlined />} />
          </Badge>
        </div>
      </Dropdown>
    </div>
  )
}