

import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { list, find, save, remove } from '../../services/contractSigned'
 
function* fetchContractSigneds({ size, index, searchTerm, orderBy }) {
  yield put({
    type: actions.REQUESTING,
  });
  const size2 = localStorage.getItem('pageSize') || size;
  const result = yield call(list, size2, index || 0, searchTerm|| '%20', orderBy|| '%20');
  yield put({
    type: actions.LIST_CONTRACTSIGNED,
    result,
  });
}

function* fetchContractSignedEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_CONTRACTSIGNED,
    result,
  });
}

function* fetchContractSignedSave(values) {
  yield put({
    type: actions.SAVE_START_RECIEVED,
  });
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
    const result2 = yield call(find, result)
    yield put({
      type: actions.EDIT_CONTRACTSIGNED,
      result: result2,
    });
  }
  yield put({
    type: actions.SAVE_END_RECIEVED,
    result,
  }); 
}

function* fetchContractSignedDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_CONTRACTSIGNED_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_CONTRACTSIGNEDS,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_CONTRACTSIGNEDS, fetchContractSigneds),
    takeEvery(actions.FETCH_CONTRACTSIGNED, fetchContractSignedEdit),
    takeEvery(actions.SAVE_CONTRACTSIGNED, fetchContractSignedSave),
    takeEvery(actions.DELETE_CONTRACTSIGNED, fetchContractSignedDelete),
    takeEvery(actions.RE_FETCH_CONTRACTSIGNEDS, fetchContractSigneds),
  ])
}
