const actions = {
  REQUESTING: 'SepaRechnungs/REQUESTING',
  RESPONSE_ERROR: 'SepaRechnungs/RESPONSE_ERROR',

  FETCH_SEPARECHNUNGS: 'SepaRechnungs/FETCH',
  LIST_SEPARECHNUNG: 'SepaRechnung/LIST',

  FETCH_SEPARECHNUNG: 'SepaRechnung/FETCH',
  NEW_SEPARECHNUNG: 'SepaRechnung/NEW',
  EDIT_SEPARECHNUNG: 'SepaRechnung/EDIT',
  RESET_SEPARECHNUNG: 'SepaRechnung/RESET',

  RE_FETCH_SEPARECHNUNGS: 'SepaRechnungs/REFRESH',
  SAVE_SEPARECHNUNG: 'SepaRechnung/SAVE',
  SAVE_SEPARECHNUNG_RECIEVED: 'SepaRechnung/SAVE_SEPARECHNUNG_RECIEVED',

  DELETE_SEPARECHNUNG: 'SepaRechnung/DELETE',
  DELETE_SEPARECHNUNG_RECIEVED: 'SepaRechnung/DELETE_RECIEVED',
}

export default actions
